<app-navbar></app-navbar>
<ng-container *ngIf="organization$ | async as organization">
  <div class="org-nav" *ngIf="organization">
    <div class="container d-flex">
      <div class="d-flex flex-column">
        <app-organization-switcher
          class="my-auto pl-1"
          [activeOrganization]="organization"
        ></app-organization-switcher>
        <ul class="nav nav-tabs">
          <li class="nav-item">
            <a class="nav-link" routerLink="vault" routerLinkActive="active">
              <i class="bwi bwi-lock" aria-hidden="true"></i>
              {{ "vault" | i18n }}
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowManageTab(organization)">
            <a
              class="nav-link"
              [routerLink]="getManageRoute(organization)"
              routerLinkActive="active"
            >
              <i class="bwi bwi-sliders" aria-hidden="true"></i>
              {{ "manage" | i18n }}
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowToolsTab(organization)">
            <a
              class="nav-link"
              [routerLink]="getToolsRoute(organization)"
              routerLinkActive="active"
            >
              <i class="bwi bwi-wrench" aria-hidden="true"></i>
              {{ "tools" | i18n }}
            </a>
          </li>
          <li class="nav-item" *ngIf="canShowSettingsTab(organization)">
            <a class="nav-link" routerLink="settings" routerLinkActive="active">
              <i class="bwi bwi-cogs" aria-hidden="true"></i>
              {{ "settings" | i18n }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
<router-outlet></router-outlet>
<app-footer></app-footer>
